.weezi-logo {
	height: 16px;
	margin: 0 0 2px 4px;
}

.weezi-logo:hover g > .w2 {
	opacity: 1;
	transform: translateX(0) rotate(0deg);
}

.weezi-logo:hover g > .w3 {
	transition-delay: 150ms;
	opacity: 1;
	transform: translateX(0);
}

.weezi-logo:hover g > .w4 {
	transition-delay: 250ms;
	opacity: 1;
	transform: translateX(0);
}

.weezi-logo:hover g > .w5 {
	transition-delay: 350ms;
	opacity: 1;
	transform: translateX(0);
}

.weezi-logo .anim-word {
	animation-timing-function: ease-out;
	transform-box: fill-box;
	transform-origin: 50% 50%;
	transition: all 0.5s;
	opacity: 0;
}

.weezi-logo .w2 {
	transform: translateX(-17px) rotate(-90deg);
}

.weezi-logo .w3 {
	transform: translateX(-30px);
}

.weezi-logo .w4 {
	transform: translateX(-45px);
}

.weezi-logo .w5 {
	transform: translateX(-60px);
}
